import "typeface-lato";
import "typeface-open-sans";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const defaultOptions = {
  margin: 20,
  zIndex: 42
};
