// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-custom-page-js": () => import("./../src/templates/custom-page.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-buy-js": () => import("./../src/templates/buy.js" /* webpackChunkName: "component---src-templates-buy-js" */),
  "component---src-templates-shop-page-js": () => import("./../src/templates/shop-page.js" /* webpackChunkName: "component---src-templates-shop-page-js" */),
  "component---src-templates-training-page-js": () => import("./../src/templates/training-page.js" /* webpackChunkName: "component---src-templates-training-page-js" */),
  "component---src-templates-training-js": () => import("./../src/templates/training.js" /* webpackChunkName: "component---src-templates-training-js" */),
  "component---src-templates-training-buy-js": () => import("./../src/templates/training-buy.js" /* webpackChunkName: "component---src-templates-training-buy-js" */),
  "component---src-templates-faq-js": () => import("./../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-frequently-asked-questions-index-js": () => import("./../src/pages/frequently-asked-questions/index.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-index-js" */),
  "component---src-pages-shop-thankyou-js": () => import("./../src/pages/shop/thankyou.js" /* webpackChunkName: "component---src-pages-shop-thankyou-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

